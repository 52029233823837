@import '../../utils/vars';

.memberFeed {
  width: 100%;

  .flexDisplay {
    justify-content: center;
    margin-bottom: $margin-small;
  }

  .header {
    text-transform: uppercase;
    background-color: $background-color;
    color: $txt-colour;
    font-weight: normal;
    text-align: center;
    margin-top: $margin-large;
    font-size: $brand-section-text-large;
    font-family: $font-special, $fallback-font, $fallback-font2, $fallback-font3, $fallback-font4;
    margin-bottom: 0.5rem;
    width: 100%;

    @media screen and (max-width: $md) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    @media screen and (max-width: $sm) {
        font-size: $brand-section-text-small;
        margin-bottom: 1.5rem;
        margin-top: $margin-text-small;
    }
  }
}
