@import '../../utils/vars';

.memberItem {
    flex: 0 0 calc(94.5% / 4);
    max-width: calc(94.5% / 4);
    margin-top: $margin-small;

  @media screen and (max-width: $lg) {
      flex: 0 0 calc(100% / 3);
      max-width: calc(100% / 3);
  }

  margin-bottom: 16px;

  @media screen and (max-width: $sm) {
    margin-top: 0;
  }
  
  .squareImage {
    margin-bottom: 16px;
  }

  .title {
      font-family:  $font, $fallback-font, $fallback-font2, $fallback-font3, $fallback-font4;
      font-size: $charter-member-text-large;
      letter-spacing: -0.15px;
      @media screen and (max-width: $lg) {
        font-size: $charter-member-text-small;
      }
  }
}
