@import '../../utils/vars';

.squareImage {
    position: relative;

    &::before {
        content: '';
        padding-top: 100%;
        display: block;
    }

    .overlay {
        top: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.4);
        padding-top: 45%;
        opacity: 0;
        transition: all 0.25s ease-in;
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: right;
        text-decoration: none;

        .excerpt {
            text-align: center;
            width: 65%;
            margin: 0 auto;
        }

        .readMore {
            position: absolute;
        }

        .excerpt,
        .readMore {
            color: $inverted-colour;
        }

        .readMore {
            bottom: 15px;
            right: 35px;
        }

        &:hover,
        &:focus {
            opacity: 1;
        }
    }

    img {
        top: 0;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .memberOverlay {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.16);
        color: $inverted-colour;
        text-transform: uppercase;
        font-size: 29px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        padding-top: 40%;

        @media screen and (max-width: $xl){
            font-size: 24px;
        }

        @media screen and (max-width: $lg){
            font-size: 32px;
        }

        @media screen and (max-width: $sm){
            font-size: 14px;
        }
        
        @media screen and (max-width: $xs){
            font-size: 10px;
        }
       
        .overlayText {
            position: absolute;
            font-weight: bold;
            font-family: $font, $fallback-font, $fallback-font2, $fallback-font3, $fallback-font4;
            line-height: 1.2;
            letter-spacing: 1px;
            width: 100%;
            text-align: center;
            padding: 0 5px;
        }
    }
}
