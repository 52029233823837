@import '../../utils/vars';

.infoPanelDesktop {

    @media screen and (max-width: $sm) {
        display: none
    }

    @media screen and (max-width: $md) {
        margin: 20px 0px;
    }

    justify-content: center;
    margin: 100px 0px;

   
    .linkBoxMain {
        padding: 40px auto !important;
    }

    .linkBoxGallery {
        display: flex;

        .linkBoxGalleryLeft {
            display: flex;
            flex-direction: column;

        }
    }
}

.infoPanelMobile {

    @media screen and (min-width: $sm) {
        display: none
    }

    @media screen and (max-width: $sm) {
        margin: 100px 0px 20px 0px;
    }
    @media screen and (max-width: $xs) {
        margin: 10px 0px 20px 0px;
    }

    justify-content: center;
    margin: 100px 0px 50px 0px;

   
    .linkBoxMain {
        padding: 40px auto !important;
    }

    .linkBoxGallery {
        display: flex;

        .linkBoxGalleryLeft {
            display: flex;
            flex-direction: column;

        }
    }
}
