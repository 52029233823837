@import '../../utils/vars';

.linkBoxGallery {

  h3,
  h2,
  img {
      transition: all .1s ease-in;

      @media screen and (min-width: $lg) {
        transition: all .3s ease-in;
      }
  }

  img {
    filter: brightness(80%);
  }

  .linkBox:hover h2 {
    transform: scale(1.03);
 }

  .linkBox:hover img {
    filter: brightness(100%);
  }

  margin: 40px auto;
  display: flex;
  gap: 15px;

  .gallery {
    display: flex;
    gap: 20px;
  }

  .linkBoxGalleryLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    flex: 1 0 46%;
  }
  
  .linkBoxGalleryRight {
    //flex: 1 0 45%;

    .linkBox {
      display: flex;
      justify-content: center;

      .text {
        position: absolute;
        left: 32%;
        top: 60%;
      }
    }
  }

  .linkBox {
    position: relative;
    width: 100%;
    
    img {
      max-width: 100%;
      width: 100%;
    }
    
    .text {
      position: absolute;
      left: 40%;
      top: 20%;
      width: 100%;
      transform: translate(-50%, 50%);
      color: $inverted-colour;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media screen and (max-width: $lg) {
        left: 47%;
      }

      @media screen and (max-width: $md) {
          // bottom: 0;
          // right: 10px;
      } 
      
      h3 {
        margin-bottom: 5px;
        font-size: 24px;

        @media screen and (max-width: 1400px) {
          font-size: 20px;
          margin-bottom: 0px;
        }

        @media screen and (max-width: $md) {
          font-size: 16px;
        }
      }
      
      h2 {
        text-transform: uppercase;
        font-size: 42px;
        font-weight: bold;
        text-decoration: none;
        color: $inverted-colour;

        @media screen and (max-width: 1400px) {
          font-size: 36px;
        }

        @media screen and (max-width: $lg) {
          font-size: 30px;
        }
       
        @media screen and (max-width: $md) {
            font-size: 22px;
        }
      }
    }
  }
}
