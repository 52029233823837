@import '../../utils/vars';

.linkBox {
  margin: 40px auto;
  position: relative;

 &:hover h2 {
    transform: scale(1.02);
 }

  img {
      width: 100%;
      filter: brightness(80%);
    }

    &:hover img {
      filter: brightness(100%);
    }

    h3,
    h2,
    img {
        transition: all .25s ease-in;
    }

  .text {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: $inverted-colour;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    width: 85%;

    @media screen and (max-width: 1500px) {
      width: 95%;
    }

    @media screen and (max-width: 1325px) {
      width: 85%;
    }

    @media screen and (max-width: $lg) {
      width: 95%;
    }

    @media screen and (max-width: $md) {
        bottom: 0;
        right: 10px;
        width: 100%;
    }

    @media screen and (max-width: 320px) {
      width: 95%;
    }

    h3 {
        margin: 0;
        font-size: 20px;
        line-height: 1rem;
    }
    
    h2 {
        font-size: 70px;
        font-weight: bold;
        text-decoration: none;
        color: $inverted-colour;
        text-wrap: wrap;  

        @media screen and (max-width: 1325px) {
          font-size: 60px;
        }

        @media screen and (max-width: 1029px) {
          font-size: 50px;
        }

        @media screen and (max-width: $lg) {
          font-size: 45px;
        }

        @media screen and (max-width: $md) {
          font-size: 40px;
        }

        @media screen and (max-width: $sm) {
          font-size: 40px;
          margin-top: 5px;
        }

        @media screen and (max-width: 320px) {
          font-size: 20px;
        }
    }
  }
}
