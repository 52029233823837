@import '../../utils/vars';

@mixin invert-colors {
    background-color: $inverted-colour;
    color: $colour;
}

.srOnly {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

.bannerVideo {
    height: 95vh;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-color: black;
    padding-top: 80px;
    margin-bottom: 0;
}

.stockTicker {
    display: block;
    background-color: #F4B7DB;
    width: 100%;
    text-align: center;
    font-family: $font-special;
    letter-spacing: 0.3rem;
    font-weight: bold;
    color: $txt-colour;
    padding: 10px 0 5px 0;

    @media screen and (max-width: $md) {

        h6 {
            letter-spacing: 0.07rem;
            font-size: 12px;
        }
    }

    @media screen and (max-width: $xs) {

        h6 {
            letter-spacing: 0.04rem;
            font-size: 10px;
        }
    }

    @media screen and (max-width: $iphone5w) {

        h6 {
            letter-spacing: 0.04rem;
            font-size: 10px;
        }
    }
}
